import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { HeaderMedium, BaseTransparentP } from 'components/typography';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';

export const Wrapper = styled(WidthWrapper).attrs({ size: 980 })`
	max-width:1030px;
	${HeaderMedium} {
		padding-top: 20px;
		padding-bottom: 20px;
		text-align: center;
	}

	table {
		table-layout: fixed ;
	}

	#customers {
		font-family: CircularStd;
		border-collapse: collapse;
		width: 100%;
		margin-bottom: 50px;
		overflow-x: auto;
	}

	#customers td {
		font-size:17px;
	}

	#customers td p {
		font-size: 12px;
    	color: #9db1ad;
    	font-weight: 500;
    	line-height: 1.5;
	}

	#customers td span {
		font-size: 12px;
    	color: #9db1ad;
    	font-weight: 500;
    	line-height: 1.5;
	}

	#customers td, #customers th {
		border: 1px solid #ddd;
		padding: 8px;
		text-align:center !important;
	}

	#customers th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background-color: #ccddd8 ;
		color: #00AF85;
	}

	#customers th span {
		color: #00AF85 !important;
	}
	
	#customers > tr:nth-child(2) {
		background:transparent;
	}

	small {
		margin-bottom: 10px;
	}

	h3 {
		margin-bottom: 5px;
	}

	#customers tr.no-bg {
		background-color:#fff !important;
	}

	.prep ul{
		text-align: left;
	}

	#customers tr:hover {background-color: #fff !important;}

	@media 
	only screen and (max-width: 1023px) {
		${HeaderMedium} {
			padding-top: 35px;
			padding-bottom: 20px;
			text-align: center;
		}

		.table-responsive {
			width: 100%;
			margin-bottom: 15px;
			overflow-y: hidden;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			border: 1px solid #ddd;
		}

		.table-responsive > .table {
			margin-bottom: 0;
		}

		.table-responsive > .table > thead > tr > th,
		.table-responsive > .table > tbody > tr > th,
		.table-responsive > .table > tfoot > tr > th,
		.table-responsive > .table > thead > tr > td,
		.table-responsive > .table > tbody > tr > td,
		.table-responsive > .table > tfoot > tr > td {
			white-space: nowrap;
		}

		table#customers {
    	  font-family: CircularStd;
		  border-collapse: collapse;
		  margin-bottom: 50px;
	    }

	    table#customers th{
	      width: 150px;
	    }
	}

`;