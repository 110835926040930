import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { HeaderMedium, BaseTransparentP } from 'components/typography';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import Layout from 'components/Layout';
import { Wrapper } from './styled';

const WrapperLanguage = styled(Wrapper)`
	#customers ul {
		padding-right:20px !important;
	}
	.table-desc {
		font-size: 18px;
		line-height: 1.5;
		margin-bottom:30px;
	}
	.table-desc a {
		color: #00af85;
	}
	.en .zh {
    	display:none;
  	}

  	.en .kr {
    	display:none;
  	}

  	.en .ja {
    	display:none;
  	}

  	.zh .en {
    	display:none;
  	}

  	.zh .kr {
  	  display:none;
  	}

  	.zh .ja {
    	display:none;
  	}

  	.kr .en {
  	  display:none;
  	}

  	.kr .zh {
    	display:none;
  	}

  	.kr .ja {
    	display:none;
  	}

  	.ja .en {
    	display:none;
  	}

  	.ja .zh {
    	display:none;
  	}

  	.ja .kr {
    	display:none;
  	}
	
	span.en {
		color:#000 !important;
	}

	span.zh {
		color:#000 !important;
	}

	span.kr {
		color:#000 !important;
	}

	span.ja {
		color:#000 !important;
	}
`;


const PrepForwarding = () => {
	const intl = useIntl()
	return(
		<RelativeWrapper>
			<WrapperLanguage>
				<HeaderMedium>{intl.formatMessage({ id: "p-pricing-s4-title" })}</HeaderMedium>
				<h3>{intl.formatMessage({ id: "p-pricing-s4-table1-title" })}</h3>
				<p className="table-desc">
					{intl.formatMessage({ id: "p-fba-desc" })}
				</p>
				<div className="table-responsive">
					<table id="customers" className="prep">
					<tbody>
					  <tr>
					    <th>{intl.formatMessage({ id: "p-fba-table-title-1" })}</th>
					    <th>{intl.formatMessage({ id: "p-fba-table-title-2" })}</th>
					  </tr>
					  <tr>
					  	<td>
					  		<ul>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col1-list1" })}</li>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col1-list2" })}</li>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col1-list3" })}</li>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col1-list4" })}</li>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col1-list5" })}</li>
					  		</ul>
					  	</td>
					    <td>
					    	<ul>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col3-list1" })}</li>
					  			<li>{intl.formatMessage({ id: "p-pricing-s4-table1-col3-list2" })}</li>
					  		</ul>
					  	</td>
					  </tr>
					</tbody>
					</table>
				</div>
			</WrapperLanguage>
			<WrapperLanguage>
				<h3>{intl.formatMessage({ id: "p-pricing-s4-table2-title" })}</h3>
				<p className="table-desc">
					{intl.formatMessage({ id: "p-fba-desc-2" })}
				</p>
				<small>{intl.formatMessage({ id: "p-fba-desc-small" })}</small>
				<div className="table-responsive">
					<table id="customers" className={intl.formatMessage({ id: "language" })}>
					<tbody>
					  <tr>
					    <th></th>
					    <th>
					    	<span className="en">China to <br />United States</span>
					    	<span className="zh">中国到美国</span>
					    	<span className="kr">미국으로 가는 중국</span>
					    	<span className="ja">中国から米国</span>
					    </th>
					    <th>
					    	<span className="en">China to <br />Germany</span>
					    	<span className="zh">中国到德国</span>
					    	<span className="kr">독일로 가는 중국</span>
					    	<span className="ja">中国からドイツへ</span>
					    </th>
					    <th>
					    	<span className="en">China to <br />United Kingdom</span>
					    	<span className="zh">中国到英国</span>
					    	<span className="kr">영국으로 가는 중국</span>
					    	<span className="ja">中国から英国へ</span>
					    </th>
					    <th>
					    	<span className="en">China to <br />France</span>
					    	<span className="zh">中国到法国</span>
					    	<span className="kr">프랑스로 가는 중국</span>
					    	<span className="ja">中国からフランスへ</span>
					    </th>
					  </tr>
					  <tr className="no-bg">
					    <td className="en">50kg +</td>
					    <td className="zh">50公斤以上</td>
					    <td className="kr">50kg +</td>
					    <td className="ja">50kg +</td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$6.1  
					    	<span className="en">  /kg</span>
					    	<span className="kr">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$4.2  
					    	<span className="en">  /kg</span>
					    	<span className="kr">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$4.5  
					    	<span className="en">  /kg</span>
					    	<span className="kr">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$4.5  
					    	<span className="en">  /kg</span>
					    	<span className="kr">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					  </tr>
					  <tr className="no-bg">
					    <td className="en">100kg +</td>
					    <td className="zh">100公斤以上</td>
					    <td className="kr">100kg +</td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$5.8  
					    	<span className="en">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="kr">  /kg</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$4  
					    	<span className="en">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="kr">  /kg</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$4.3  
					    	<span className="en">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="kr">  /kg</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p>
					    	<span className="zh">每公斤</span>$4.3  
					    	<span className="en">  /kg</span>
					    	<span className="zh">起</span>
					    	<span className="kr">  /kg</span>
					    	<span className="ja">  /kg</span>
					    	<span className="ja">  から</span>
					    </td>
					  </tr>
					  <tr className="no-bg">
					    <td>{intl.formatMessage({ id: "p-pricing-s3-col1-list" })}</td>
					    <td>5-7</td>
					    <td>10-14</td>
					    <td>10-14</td>
					    <td>10-14</td>
					  </tr>
					</tbody>  
					</table>
				</div>
			</WrapperLanguage>
		</RelativeWrapper>
	);
};
  

export default PrepForwarding;