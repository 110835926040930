import React from 'react';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import Pricing from 'components/pages/Pricing';
import Layout from 'components/Layout';
import Helmet from "react-helmet";
import Fulfillment from 'components/pages/Pricing/Fulfillment';
import Shipping from 'components/pages/Pricing/Shipping';
import PrepForwarding from 'components/pages/Pricing/PrepForwarding';

const PricingPage = () => {
  const intl = useIntl()
  return(
    <Layout>
      <Helmet>
        {/* <title>Pricing | Global Ecommerce Fulfillment Partner | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-pricing" })}</title>
        <link rel="alternate" href={intl.formatMessage({ id: "pricing-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "pricing-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "pricing-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "pricing-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="Take a look of Shipkoo's pricing for any businesses in mind. We offer a great shipping and fulfillment solutions as well as a great pricing for your business!" />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-pricing" })} />
        <meta property="og:description" content="Take a look of Shipkoo's pricing for any businesses in mind. We offer a great shipping and fulfillment solutions as well as a great pricing for your business!" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-pricing" })} />
        <meta name="twitter:description" content="Take a look of Shipkoo's pricing for any businesses in mind. We offer a great shipping and fulfillment solutions as well as a great pricing for your business!" />
      </Helmet>
      <p className="breadcrumbs"><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-pricing" })}</p>
      <Pricing />
      <Fulfillment />
      <Shipping />
      <PrepForwarding />
    </Layout>
  );
};

export default PricingPage;
