import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from 'gatsby';
import { mediaMin } from 'common/styledMixins';
import { breakpoint, zIndex } from 'common/styledVars';
import { HeaderMedium, BaseTransparentP, BaseP } from 'components/typography';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import Layout from 'components/Layout';
import ArrowButton from 'components/Button/ArrowButton';
import { ImageBox, GatsbyImage } from 'components/pages/Partners/styled';
import { MobileFilter } from 'components/BackgroundFilter';

const Wrapper = styled(WidthWrapper).attrs({ size: 980 })`
  	${HeaderMedium} {
     	padding-top: 40px;
    	text-align:left;
  	}

  	${BaseTransparentP} {
    	margin: 20px 0 41px;
    	text-align:left;
	}
 	
 	${BaseP} {
 		margin-top: 70px;
 	}

 	@media 
	only screen and (max-width: 768px)  {
		${HeaderMedium} {
	     	padding-top: 40px;
	    	text-align:left;
	    	color: white !important;
	  	}

	  	${BaseTransparentP} {
	    	margin: 20px 0 41px;
	    	text-align:left;
	    	color: white !important;
		}
	 	
	 	${BaseP} {
	 		margin-top: 70px;
	 		color: white !important;
	 	}
	}
`;

const MainTitle = styled.h1`
    padding-top: 40px;
    text-align: left;
    font-size: 28px;
    font-weight: 500;
  
  @media ${mediaMin(breakpoint.desktop)} {
      font-size: 36px;
  }

  @media only screen and (max-width: 768px)  {
     color:#fff;
  }
`;

const MainWrapper = styled(RelativeWrapper)`
	${ImageBox}{
		padding-top:70px;
	    margin-right: 39px;
	}

	@media 
	only screen and (max-width: 768px)  {
		${ImageBox}{
			padding-top: 0px;
	    	margin-right: 0px;
		}
	}
`;

const WrapperLanguage = styled(Wrapper)`
	.en .zh {
    	display:none;
  	}

  	.en .kr {
    	display:none;
  	}

  	.en .ja {
    	display:none;
  	}

  	.zh .en {
    	display:none;
  	}

  	.zh .kr {
  	  display:none;
  	}

  	.zh .ja {
    	display:none;
  	}

  	.kr .en {
  	  display:none;
  	}

  	.kr .zh {
    	display:none;
  	}

  	.kr .ja {
    	display:none;
  	}

  	.ja .en {
    	display:none;
  	}

  	.ja .zh {
    	display:none;
  	}

  	.ja .kr {
    	display:none;
  	}

	p.kr {
		max-width:430px;
	}

	h1.kr {
		max-width:430px;
	}
`;

const Pricing = () => {
	const intl = useIntl()
	const data = useStaticQuery(graphql`
	    query imgPricingQuery  {
	      file(relativePath: { eq: "pricing1.jpg" }) {
	        childImageSharp {
	          fluid(quality: 90, maxWidth: 1200) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	  `);

	return(
		<MainWrapper>
			<WrapperLanguage>
				<BaseP>{intl.formatMessage({ id: "p-header" })}</BaseP>
				<MainTitle className={intl.formatMessage({ id: "language" })} >{intl.formatMessage({ id: "p-subheader" })}</MainTitle>
				<div className={intl.formatMessage({ id: "language" })}>
					<BaseTransparentP className="en">
						Our shipping and fulfillment solutions are designed with<br /> your business needs in mind. Get in touch with a Shipkoo<br /> specialist to request a customized solution as per your<br/> needs and budget.
					</BaseTransparentP>
					<BaseTransparentP className="zh">
						联系我们的销售代表，根据您的需求和预算定制解决方案
					</BaseTransparentP>
					<BaseTransparentP className="kr">
						당사의 배송 및 이행 솔루션은 귀사의 비즈니스 요구를 염두에 두고 설계되었으며, Shipkoo 전문가에게 연락하여 요구 사항과 예산에 따라 맞춤형 솔루션을 요청하십시오.
					</BaseTransparentP>
					<BaseTransparentP className="ja">
						当社の配送およびフルフィルメントソリューションは<br />、ビジネスニーズを考慮して設計されています。<br /> Shipkooスペシャリストに連絡して、<br />ニーズと予算に応じてカスタマイズされ<br />たソリューションをリクエストしてください
					</BaseTransparentP>
				</div>
				<BaseTransparentP>
					<Link to="/contact">
						<ArrowButton className="cta-btn">{intl.formatMessage({ id: "p-talk-to-us" })}</ArrowButton>
					</Link>
				</BaseTransparentP>
			</WrapperLanguage>
			<ImageBox>
	          <GatsbyImage fluid={data.file.childImageSharp.fluid} alt="shipco warehouse, pick, pack ship" />
	          <MobileFilter />
	        </ImageBox>
		</MainWrapper>
	);
};
  

export default Pricing;
