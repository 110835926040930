import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { HeaderMedium, BaseTransparentP } from 'components/typography';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import Layout from 'components/Layout';
import { Wrapper } from './styled';

const HeaderMain = styled(HeaderMedium)`
	@media only screen and (min-width: 768px) {
		padding-top: 120px !important;
	}
	padding-bottom: 20px;
	text-align: center;
`;

const WrapperLanguage = styled(Wrapper)`
	.table-desc {
		font-size: 18px;
		line-height: 1.5;
		margin-bottom:30px;
	}
	.table-desc a {
		color: #00af85;
	}
	.en .zh {
    	display:none;
  	}

  	.en .kr {
    	display:none;
  	}

  	.en .ja {
    	display:none;
  	}

  	.zh .en {
    	display:none;
  	}

  	.zh .kr {
  	  display:none;
  	}

  	.zh .ja {
    	display:none;
  	}

  	.kr .en {
  	  display:none;
  	}

  	.kr .zh {
    	display:none;
  	}

  	.kr .ja {
    	display:none;
  	}

  	.ja .en {
    	display:none;
  	}

  	.ja .zh {
    	display:none;
  	}

  	.ja .kr {
    	display:none;
  	}
	
	span.en {
		color:#000 !important;
	}

	span.zh {
		color:#000 !important;
	}

	span.kr {
		color:#000 !important;
	}

	span.ja {
		color:#000 !important;
	}
`;

const Fulfillment = () => {
	const intl = useIntl()
	return(
		<RelativeWrapper>
			<WrapperLanguage>
				<HeaderMain>{intl.formatMessage({ id: "p-pricing-s2-title" })}</HeaderMain>
				<p className="table-desc">{intl.formatMessage({ id: "p-ecommerce-desc1" })} <a href="mailto:info@shipkoo.com">info@shipkoo.com</a> {intl.formatMessage({ id: "p-ecommerce-desc2" })}</p>
				<div className="table-responsive">
					<table id="customers" className={intl.formatMessage({ id: "language" })} >
					<tbody>
					  <tr>
					    <th>
					    	<span>{intl.formatMessage({ id: "p-ecommerce-table-title-1" })}</span>
					    </th>
					    <th>
					    	<span>{intl.formatMessage({ id: "p-ecommerce-table-title-2" })}</span>
					    </th>
					    <th>
					    	<span>{intl.formatMessage({ id: "p-ecommerce-table-title-3" })}</span>
					    </th>
					  </tr>
					  <tr>
					    <td><strong>{intl.formatMessage({ id: "p-ecommerce-table-service-1" })}</strong></td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p> $0.06
					    	<span className="zh">起</span>
					    	<span className="ja">から</span>
					    </td>
					    <td>
					    	{intl.formatMessage({ id: "p-ecommerce-table-unit-1" })}
					    </td>
					  </tr>
					  <tr className="no-bg">
					    <td><strong>{intl.formatMessage({ id: "p-ecommerce-table-service-2" })}</strong></td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p> $0.01
					    	<span className="zh">起</span>
					    	<span className="ja">から</span>
					    </td>
					    <td>
					    	{intl.formatMessage({ id: "p-ecommerce-table-unit-2" })}
					    </td>
					  </tr>
					  <tr className="no-bg">
					    <td><strong>{intl.formatMessage({ id: "p-ecommerce-table-service-3" })}</strong></td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p> $0.56
					    	<span className="zh">起</span>
					    	<span className="ja">から</span>
					    </td>
					    <td>
					    	{intl.formatMessage({ id: "p-ecommerce-table-unit-3" })}
					    </td>
					  </tr>
					  <tr className="no-bg">
					    <td><strong>{intl.formatMessage({ id: "p-ecommerce-table-service-4" })}</strong></td>
					    <td>
					    	<p className="en">Starting from</p>
					    	<p className="kr">시작 위치</p> $0.74
					    	<span className="zh">起</span>
					    	<span className="ja">から</span>
					    </td>
					    <td>
					    	{intl.formatMessage({ id: "p-ecommerce-table-unit-4" })}
					    </td>
					  </tr>
					 </tbody> 
					</table>
				</div>
			</WrapperLanguage>
		</RelativeWrapper>
	);
};
  

export default Fulfillment;