import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { HeaderMedium, BaseTransparentP } from 'components/typography';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import Layout from 'components/Layout';
import { Wrapper } from './styled';

const WrapperLanguage = styled(Wrapper)`
	.table-desc {
		font-size: 18px;
		line-height: 1.5;
		margin-bottom:30px;
	}
	.table-desc a {
		color: #00af85;
	}
	.en .zh {
    	display:none;
  	}

  	.en .kr {
    	display:none;
  	}

  	.en .ja {
    	display:none;
  	}

  	.zh .en {
    	display:none;
  	}

  	.zh .kr {
  	  display:none;
  	}

  	.zh .ja {
    	display:none;
  	}

  	.kr .en {
  	  display:none;
  	}

  	.kr .zh {
    	display:none;
  	}

  	.kr .ja {
    	display:none;
  	}

  	.ja .en {
    	display:none;
  	}

  	.ja .zh {
    	display:none;
  	}

  	.ja .kr {
    	display:none;
  	}
	
	span.en {
		color:#000 !important;
	}

	span.zh {
		color:#000 !important;
	}

	span.kr {
		color:#000 !important;
	}

	span.ja {
		color:#000 !important;
	}
`;

const Shipping = () => {
	const intl = useIntl()
	return(
		<RelativeWrapper>
			<WrapperLanguage>
				<HeaderMedium>{intl.formatMessage({ id: "p-pricing-s3-title" })}</HeaderMedium>
				<p className="table-desc">{intl.formatMessage({ id: "p-shipping-desc" })}</p>
				<small>{intl.formatMessage({ id: "p-shipping-desc-small" })}</small>
				<div className="table-responsive">
				<table id="customers" className={intl.formatMessage({ id: "language" })}>
				<tbody>
				  <tr>
				    <th></th>
				    <th>
				    	<span className="en">China to <br />United States</span>
				    	<span className="zh">中国到美国</span>
				    	<span className="kr">미국으로 가는 중국</span>
				    	<span className="ja">中国から米国</span>
				    </th>
				    <th>
				    	<span className="en">China to <br />Germany</span>
				    	<span className="zh">中国到德国</span>
				    	<span className="kr">독일로 가는 중국</span>
				    	<span className="ja">中国からドイツへ</span>
				    </th>
				    <th>
				    	<span className="en">China to <br />United Kingdom</span>
				    	<span className="zh">中国到英国</span>
				    	<span className="kr">영국으로 가는 중국</span>
				    	<span className="ja">中国から英国へ</span>
				    </th>
				    <th>
				    	<span className="en">China to <br />France</span>
				    	<span className="zh">中国到法国</span>
				    	<span className="kr">프랑스로 가는 중국</span>
				    	<span className="ja">中国からフランスへ</span>
				    </th>
				    <th>
				    	<span className="en">China to <br />South Korea</span>
				    	<span className="zh">中国飞往韩国</span>
				    	<span className="kr">중국에서 한국으로</span>
				    	<span className="ja">中国から韓国へ</span>
				    </th>
				  </tr>
				  <tr className="no-bg">
				    <td>{intl.formatMessage({ id: "p-shipping-table-subtitle-1" })}</td>
				    <td>
				    	<p className="en">Starting from</p>
				    	<p className="kr">시작 위치</p> $5.2
				    	<span className="zh">起</span>
				    	<span className="ja">から</span>
				    </td>
				    <td>
				    	<p className="en">Starting from</p>
				    	<p className="kr">시작 위치</p> $5
				    	<span className="zh">起</span>
				    	<span className="ja">から</span>
				    </td>
				    <td>
				    	<p className="en">Starting from</p>
				    	<p className="kr">시작 위치</p> $4.5 
				    	<span className="zh">起</span>
				    	<span className="ja">から</span>
				    </td>
				    <td>
				    	<p className="en">Starting from</p>
				    	<p className="kr">시작 위치</p> $5.3
				    	<span className="zh">起</span>
				    	<span className="ja">から</span>
				    </td>
				    <td>
				    	<p className="en">Starting from</p>
				    	<p className="kr">시작 위치</p> $3.9
				    	<span className="zh">起</span>
				    	<span className="ja">から</span>
				    </td>
				  </tr>
				  <tr className="no-bg">
				    <td>{intl.formatMessage({ id: "p-pricing-s3-col1-list" })}</td>
				    <td>5-7</td>
				    <td>5-7</td>
				    <td>5-7</td>
				    <td>4-7</td>
				    <td>4-5</td>
				  </tr>
				 </tbody>
				</table>
				</div>
			</WrapperLanguage>
		</RelativeWrapper>
	);
};
  

export default Shipping;